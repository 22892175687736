import { reactive, ref } from 'vue';
import { awaitWrap } from '@/util/index';
import _ from 'lodash';

export interface UseTable<T> {
  parmas: T;
  getParmas: Function;
  load?: Boolean;
}

//表单hooks
export function useTable(option: UseTable<any>): any {
  const isLoad  = option.load === undefined?true:  option.load;

  //搜索数据
  const searchParmas = reactive(_.clone(option.parmas));

  //重置表单
  function handleReset() {
    _.assignIn(searchParmas, option.parmas);
  }

  //分页数据
  const pagination = reactive({
    current: 1,
    total: 10,
  });

  //表格数据
  const tableSource = ref([]);
  const tableLoading = ref(false);

  // 获取列表
  async function getList() {
    tableLoading.value = true;
    const { code, data } = await awaitWrap(option.getParmas(searchParmas));
    tableLoading.value = false;
    if (code === 200) {
      tableSource.value = data.data.DataList || data.data;
      pagination.total = data.data.RecordCount;
    }
  }

  //分页操作
  function handlePageChange(val: any) {
    pagination.current = val;
    searchParmas.PageIndex = val;
    getList();
  }

  if(isLoad){
    getList();
  }


  return {
    tableLoading,
    tableSource,
    pagination,
    searchParmas,
    handlePageChange,
    getList,
    handleReset,
  };
}
